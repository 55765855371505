import { createContext, Dispatch, ReactElement, ReactNode, SetStateAction, useContext, useMemo } from "react";

import Card from "components/Card";
import CardStack from "components/CardStack";
import { CodeViewLanguages } from "components/CodeView";
import { RadioGroup, RadioGroupElement } from "components/RadioGroup";
import Tabs, { Tab, TabProps } from "components/Tab";

import { useLocalStorage } from "hooks/useLocalStorage";

import { SupportedLanguages } from "routes/dashboard/ApiPlaygroundTypes";
import { useUser } from "routes/dataroutes/UserData";

import { apiExampleResult } from "./apiExampleResult";
import ApiQuickStartSteps from "./ApiQuickStartSteps";
import ApiResources from "./ApiResources";
import DataPipelineQuickStartSteps from "./DataPipelineQuickStartSteps";
import { QuickStartCodeView } from "./QuickStartCodeView";
import SDEQuickStartSteps from "./SDEQuickStartSteps";
import SDEResources from "./SDEResources";
import examples from "./sde-examples";

import { cx } from "utils";


function QuickStartNumber(
  {
    hasIcon,
    className,
    children
  }: {
    hasIcon: boolean;
    className?: string;
    children?: ReactNode;
  }
) {
  return (
    <div className={ cx(
      "flex items-center shrink-0 justify-center w-[24px] h-[24px] rounded",
      hasIcon && "bg-accent-600 text-white",
      !hasIcon && "bg-primary-100 text-primary-600 font-semibold text-sm",
      className
    ) }
    >
      { children }
    </div>
  );
}

export function QuickStartStep(
  {
    title,
    subtitle,
    index = 1,
    icon,
    children
  }: {
    title: string | ReactNode;
    subtitle?: string | ReactNode;
    index?: number;
    icon?: ReactNode;
    children?: ReactNode;
  }
) {
  return (
    <div className="flex flex-col gap-y-3 relative group/quick-start-step">
      <div className="flex flex-row gap-x-4 items-start font-medium">
        <QuickStartNumber className="hidden _1440:flex" hasIcon={ icon !== undefined }>{ icon || index }</QuickStartNumber>
        <div className="flex flex-col">
          <div>{ title }</div>
          { subtitle && <div className="font-normal text-sm">{ subtitle }</div> }
        </div>
      </div>
      <div className="_1440:pl-10">{ children }</div>
      <div className="hidden _1440:block absolute top-6 left-3 h-full border-l border-l-primary-100 group-last/quick-start-step:hidden"/>
    </div>
  );
}

function QuickStartSteps(
  {
    children
  }: {
    children: ReactNode
  }
) {
  return (
    <div className="group/quick-start flex flex-col gap-y-5">
      { children }
    </div>
  );
}

const quickStartSteps = {
  "API": <ApiQuickStartSteps/>,
  "StructuredData": <SDEQuickStartSteps/>,
  "DataPipeline": <DataPipelineQuickStartSteps/>
};

const resources = {
  "API": <ApiResources/>,
  "StructuredData": <SDEResources/>,
  "DataPipeline": <></>
};

type Product = keyof typeof quickStartSteps;

function useDefaultOnboardingProduct(): Product {
  const user = useUser();

  if (user?.onboardedForProduct === "structured_data_endpoint") {
    return "StructuredData";
    // TODO hiding DataPipeline because that's not ready yet
  // } else if (user?.onboardedForProduct === "data_pipeline") {
  //   return "DataPipeline";
  }

  return "API";
}

type QuickStartContextType = {
  product: Product;
  setProduct: Dispatch<SetStateAction<Product>>;
  programmingLanguage: SupportedLanguages;
  setProgrammingLanguage: Dispatch<SetStateAction<SupportedLanguages>>;
  sdeProjectType: string;
  setSdeProjectType: Dispatch<SetStateAction<string>>;
};

const QuickStartContext = createContext<QuickStartContextType>(null!);

export function useQuickStartContext() {
  return useContext(QuickStartContext);
}

function useExampleResults(): { lang: CodeViewLanguages, results: string } {
  const { product, sdeProjectType } = useQuickStartContext();

  if (product === "API") {
    return {
      lang: "html",
      results: apiExampleResult
    };
  }

  if (product === "StructuredData") {
    return {
      lang: "json",
      results: examples[sdeProjectType]?.result
    };
  }

  return {
    lang: "json",
    results: "no example result"
  };
}

function ExampleResultsCodeView() {
  const { lang, results } = useExampleResults();

  return (
    <QuickStartCodeView
      language={ lang }
      codeExample={ results }
      height="100%"
      maxHeight="610px"
      canCopy={ false }
      canZoom
    />
  );
}

function QuickStartStepsSection() {
  const { product, setProduct } = useQuickStartContext();

  return (
    <QuickStartSteps>
      <QuickStartStep title="How would you like to get started?">
        <RadioGroup
          type="button"
          value={ product }
          onChange={ setProduct }
          className="!mb-0 !mt-0"
        >
          <RadioGroupElement value="API" label="API"/>
          <RadioGroupElement value="StructuredData" label="Structured Data"/>
          {/* TODO hiding DataPipeline because that's not ready yet */ }
          {/*<RadioGroupElement value="DataPipeline" label="DataPipeline"/>*/ }
        </RadioGroup>
      </QuickStartStep>
      { quickStartSteps[product] }
    </QuickStartSteps>
  );
}

function ResourcesCard() {
  const { product } = useQuickStartContext();

  return (
    <Card className="bg-white divide-y divide-neutral-100 !justify-start">
      { resources[product] }
    </Card>
  );
}

function QuickStartCardDesktop() {
  const { product } = useQuickStartContext();

  return (
    <CardStack
      className="*:bg-neutral-50"
    >
      <Card className="w-3/5">
        <QuickStartStepsSection />
      </Card>
      <Card className="w-2/5">
        { [ "API", "StructuredData" ].includes(product) && (
          <Tabs size="SM">
            <Tab title="Example result">
              <ExampleResultsCodeView/>
            </Tab>
            <Tab title="Resources">
              <ResourcesCard />
            </Tab>
          </Tabs>
        ) }
        { product === "DataPipeline" && (
          <>TODO insert DataPipeline video here</>
        ) }
      </Card>
    </CardStack>
  );
}

function QuickStartCardMobile() {
  const { product } = useQuickStartContext();

  const tabs: ReactElement<TabProps>[] = product === "DataPipeline" ?
    [
      <Tab title="Tutorial">
        <>TODO insert DataPipeline video here</>
      </Tab>
    ] :
    [
      <Tab title="Example result">
        <ExampleResultsCodeView />
      </Tab>,
      <Tab title="Resources">
        <ResourcesCard />
      </Tab>,
    ];

  return (
    <Card className="bg-neutral-50">
      <Tabs size="LG">
        { [
          <Tab title="Get started">
            <QuickStartStepsSection />
          </Tab>,
          ...tabs
        ] }
      </Tabs>
    </Card>
  );
}

export default function QuickStartCard() {
  const defaultProduct = useDefaultOnboardingProduct();
  const [ selectedProduct, setSelectedProduct ] = useLocalStorage<Product>("saOnboardingProduct", defaultProduct);
  const [ selectedLanguage, setSelectedLanguage ] = useLocalStorage<SupportedLanguages>("saOnboardingAPILanguage", "python");
  const [ selectedSdeType, setSelectedSdeType ] = useLocalStorage<string>("saOnboardingSDE", "async_amazon_product");


  const quickStartContext: QuickStartContextType = useMemo(() => {
    return {
      product: selectedProduct,
      setProduct: setSelectedProduct,
      programmingLanguage: selectedLanguage,
      setProgrammingLanguage: setSelectedLanguage,
      sdeProjectType: selectedSdeType,
      setSdeProjectType: setSelectedSdeType,
    };
  }, [ selectedProduct, setSelectedProduct, selectedLanguage, setSelectedLanguage, selectedSdeType, setSelectedSdeType ]);


  return (
    <QuickStartContext.Provider value={ quickStartContext }>
      <div className="hidden _1320:block">
        <QuickStartCardDesktop />
      </div>
      <div className="_1320:hidden">
        <QuickStartCardMobile />
      </div>
    </QuickStartContext.Provider>
  );
};
