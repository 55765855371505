import { useEffect, useState, useCallback, useMemo } from "react";
import type { Job } from "providers/HostedScrapingProvider";
import axios from "axios";
import scraperApi, { API_HOST, JobOrder } from "api";
import { COLOR_OK, COLOR_STARTED, COLOR_ERROR, COLOR_GRAY } from './ProjectColors';
import { formatDate } from './ProjectFormatDate';

import { ReactComponent as EmtpyJobListIllustration } from "assets/images/job-list-empty-illustration.svg";
import { ReactComponent as StatusDot } from "assets/icons/project-status-dot.svg";
import { ReactComponent as ProjectJobsCheckmark } from "assets/icons/project-jobs-checkmark.svg";
import { ReactComponent as ProjectJobsPlayIcon } from "assets/icons/project-jobs-play-icon.svg";
import { ReactComponent as ProjectJobsXIcon } from "assets/icons/project-jobs-x-icon.svg";

import { ClipboardIcon } from  "@heroicons/react/outline"

import Spinner from "components/Spinner";
import Button from "components/Button";
import ExtLink from "components/ExtLink";
import { LinkText } from "components/LinkText";
import { SelectOption } from "components/Select";
import { Tooltip } from "components/Tooltip";

import { formatCreditsSpent } from './creditsSpent';
import { cx } from "utils";
import { Link, useLocation } from "react-router-dom";
import { HostedScraperCancelJobModal, HostedScraperJobInfoModal } from "components/Modal";
import { SortByListBox } from "./edit-project-components/SortByListBox";

const headerClasses = 'font-droid text-slate-400 text-xs font-medium';
const lineClassName = 'font-normal text-sm text-gray dark:text-neutral-600';

type IJobListProps = {
  projectId: number;
  supposedToRunAt?: Date;
}

type IJobProp = {
  job: Job
}

type IJobAndProjectIdProp = {
  job: Job,
  projectId: number,
}

type IJobStatusProp = {
  job: Job;
  cancelJobButtonClicked: () => void;
}

const JobStatus = ({job, cancelJobButtonClicked}: IJobStatusProp) => {
  const { status, successfulTasks, failedTasks, inProgressTasks } = job;
  const aggregating = status === 'running' && inProgressTasks === 0;
  const { icon: Icon, color, text } =
      status === 'done'
    ? { icon: ProjectJobsCheckmark, color: COLOR_OK, text: 'Completed' }
    : (status === 'running' && !aggregating)
    ? { icon: ProjectJobsPlayIcon, color: COLOR_STARTED, text: 'Started' }
    : (status === 'running' && aggregating)
    ? { icon: ProjectJobsPlayIcon, color: COLOR_STARTED, text: 'Finalizing' }
    : (status === 'cancelling')
    ? { icon: ProjectJobsXIcon, color: COLOR_GRAY, text: 'Cancelling and wrapping up' }
    : (status === 'cancelled')
    ? { icon: ProjectJobsXIcon, color: COLOR_GRAY, text: 'Cancelled' }
    :  status === 'error'
    ? { icon: ProjectJobsCheckmark, color: COLOR_ERROR, text: 'Error' }
    : { icon: ProjectJobsCheckmark, color: COLOR_GRAY, text: 'Unknown' };

  const allTasks = successfulTasks + failedTasks + inProgressTasks;
  const finishedTasks = successfulTasks + failedTasks;
  const inProgressMessage = `${finishedTasks}/${allTasks} resp. finished`;
  const cancelButton = (status === 'running' && !aggregating)
    ? (
      <span>
        &nbsp;-&nbsp;
        <button onClick={cancelJobButtonClicked}>
          <LinkText>cancel</LinkText>
        </button>
      </span>
      )
    : null;

  return (
    <div className="h-16 flex flex-row flex-wrap items-center py-2">
      <div className="flex place-items-center pr-2">
        <Icon stroke={color} fill={color} />
      </div>
      <div>
        <div>
          { text }
          { cancelButton }
        </div>
        { status === 'running' && <div className="text-xs">{inProgressMessage}</div>}
      </div>
    </div>
  );
}

const DownloadErrorReportButton = () => {
  return (
    <div className="flex flex-row gap-1 items-center">
      <ClipboardIcon className="w-4 h-4"/>
      <div>
        Error report
      </div>
    </div>
  );
}

const responseStatusFieldStyle = "flex flex-row items-center pr-2 gap-1";

const JobResponseStatus = ({job}: IJobProp) => {
  const location = useLocation();
  const { successfulTasks, failedTasks, cancelledTasks } = job;
  const failureReport = job.result?.failureReport;
  const hasFailureReport = Array.isArray(failureReport) && failureReport.length > 0;
  const hasErrorReportInDOSpaces = Boolean(job.result?.failureReportDOSpacesKey);

  const canDownloadFailureReport = hasFailureReport || hasErrorReportInDOSpaces;

  const content=(
        <div>
          {successfulTasks > 0 && <div className={responseStatusFieldStyle}>
            <StatusDot stroke={COLOR_OK} fill={COLOR_OK} />
            {successfulTasks}
          </div>}
            {cancelledTasks > 0 && <div className={cx(responseStatusFieldStyle, "flex-wrap")}>
                <StatusDot stroke={COLOR_GRAY} fill={COLOR_GRAY} />
                <div className="mr-2.5">{cancelledTasks}</div>
            </div>}
            {failedTasks > 0 && <div className={cx(responseStatusFieldStyle, "flex-wrap")}>
              <StatusDot stroke={COLOR_ERROR} fill={COLOR_ERROR} />
              <div className="mr-2.5">{failedTasks}</div>
              {canDownloadFailureReport && <DownloadErrorReportButton/>}
            </div>}
          {!successfulTasks && !failedTasks && !cancelledTasks && <div className={responseStatusFieldStyle}>
            <StatusDot stroke={COLOR_GRAY} fill={COLOR_GRAY} />
            &nbsp;-&nbsp;
          </div>}
        </div>);
  if (canDownloadFailureReport) {
    return (
      <div className="h-16 flex flex-row gap-2 items-center">
        <Link
          to={`/datapipeline/project/${job.projectId}/error-report/${job.publicJobId}`}
          state={{ backgroundLocation: location }}
        >
          {content}
        </Link>
      </div>
    );
  } else {
    return (
      <div className="h-16 flex flex-row gap-2 items-center">
        {content}
      </div>);
  }
}

const buttonClass = "py-2 px-4 border border-gray dark:border-neutral-600 hover:bg-slate-50";
const disabledButtonClass = "py-2 px-4 border border-brandPrimary-100 dark:border-primary-100 text-brandPrimary-100 dark:text-primary-100";

const hasResult = (job: Job):boolean => Boolean(job.result?.doSpaces?.resultKey);
const hasCSVResult = (job: Job):boolean => job.result?.doSpaces?.format === 'zip-csv';

const FinishedAtCell = ({job, onProgressDetailsClicked}: {job: Job; onProgressDetailsClicked: () => void} ) => {
  if (job.status === 'running') {
    return (<div className="flex flex-row">
      <Spinner className="w-5 h-5 mr-2 animate-spin" />
      <div onClick={onProgressDetailsClicked}><div className="cursor-pointer underline text-brandDarkest dark:text-primary-800 underline-offset-2">Progress details</div></div>
    </div>);
  } else {
    return <>{formatDate(job.finishedAt)}</>;
  }
}

const DownloadResultButton = ({job}: IJobProp) => {
  if (!hasResult(job)) {
    return <button disabled className={disabledButtonClass}>Download</button>
  } else {
    const label = hasCSVResult(job) ? 'Download (CSV)' : 'Download (JSON)';
    return (
        <ExtLink href={`${API_HOST}/hostedscraping/job/${job.publicJobId}/result`}>
          <button className={buttonClass}>{label}</button>
        </ExtLink>
    );
 }
}

const DeliveryButton = ({job, projectId}: IJobAndProjectIdProp) => {
  const [ sendingInProgress, setSendingInProgress ] = useState(false);

  const resendFn = useCallback(async() => {
    setSendingInProgress(true);
    try {
      await scraperApi.hostedScraping.resendJobResult(projectId, job.id);
    } finally {
      setSendingInProgress(false);
    }
  }, [job.id, projectId])

  if (job.status === 'running') {
    return <span>-</span>;
  } else if (job.result?.webhook) {
    return (<div className="flex flex-wrap gap-2">
      <span>Webhook</span>
      { sendingInProgress
        ? (<Spinner className="w-5 h-5 mr-2 animate-spin" />)
        : (<button onClick={resendFn}>- <span className="text-brandPrimary dark:text-primary-600">Resend</span></button>)
      }
    </div>);
  } else if (hasResult(job)) {
    return (<span>Download</span>);
  } else {
    return <span>No result</span>;
  }
}

const jobListHeader = () => (
  <tr className="text-left">
    <th className={headerClasses}>STATUS</th>
    <th className={headerClasses}>
      <Tooltip content="If your job status says 'error', you can download a status report by clicking on the numbers in the column 'No. of responses'.">NO. OF RESPONSES</Tooltip>
    </th>
    <th className={headerClasses}>STARTED AT</th>
    <th className={headerClasses}>FINISHED AT</th>
    <th className={headerClasses}>COST</th>
    <th className={headerClasses}>DELIVERY</th>
    <th className={headerClasses}>RESULT</th>
  </tr>
);

const jobLine = (
  projectId: number,
  onProgressDetailsClicked: (jobId: number) => void,
  onCancelJobClicked: (jobId: number) => void
) => (job: Job) => {
    const startDate = job.startedAt || job.createdAt;
    return (<tr key={job.id} className="">
      <td className={lineClassName}><JobStatus job={job} cancelJobButtonClicked={() => onCancelJobClicked(job.id)}/></td>
      <td className={lineClassName}><JobResponseStatus job={job}/></td>
      <td className={lineClassName}>{formatDate(startDate)}</td>
      <td className={lineClassName}><FinishedAtCell job={job} onProgressDetailsClicked={() => onProgressDetailsClicked(job.id)}/></td>
      <td className={lineClassName}><div className="px-1">{formatCreditsSpent(job.credits)}</div></td>
      <td className={lineClassName}><div className="px-1"><DeliveryButton job={job} projectId={projectId} /></div></td>
      <td className={lineClassName}><DownloadResultButton job={job}/></td>
    </tr>);
};

const EmptyJobList = ({firstJobScheduled}:{firstJobScheduled?: Date}) => {
  return (
    <div className="flex flex-col items-center justify-center gap-2 my-40 text-center text-gray dark:text-neutral-600">
      <EmtpyJobListIllustration />
      <div className="text-gray dark:text-neutral-600 font-droid text-base font-semibold">No jobs yet</div>
      <div>
        {firstJobScheduled && <div>Your project is scheduled to start at <span className="font-semibold">{formatDate(firstJobScheduled)}</span></div>}
        {!firstJobScheduled && <div>Start your first job by clicking on the "RUN JOB NOW" button on top or use the frequency feature to run a job at a later point in your preferred intervals. </div>}
      </div>
    </div>
  );
}

const sortByOptions: SelectOption<JobOrder>[] = [
  { name: "Job completed", value: "job_completed_first" },
  { name: "Job failed", value: "job_failed_first" },
  { name: "Last run first", value: "last_run_first" },
  { name: "Last run last", value: "last_run_last" },
];

export function JobList({projectId, supposedToRunAt}: IJobListProps) {
  const [ jobs, setJobs ] = useState<Job[] | null>(null);
  const [ numberOfJobs, setNumberOfJobs ] = useState<number>(5);
  const [ inProgress, setInProgress ] = useState<boolean>(true);
  const [ progressDetailsJobId, setProgressDetailsJobId ] = useState<number | null>(null);
  const [ activeCancelJobModalJobId, setActiveCancelJobModalJobId ] = useState<number | null>(null);
  const [ jobOrder, setJobOrder ] = useState<JobOrder>('last_run_first');

  const fetchJobs = useCallback(
    async (controller: AbortController | undefined) => {
      if (projectId !== null) {
        const jobs = await scraperApi.hostedScraping.jobs(projectId, numberOfJobs, jobOrder, {
          signal: controller?.signal,
        });
        setJobs(jobs);
      }
    },
    [projectId, numberOfJobs, jobOrder]
  );


  useEffect(() => {
    const controller = new AbortController();
    const refreshJobs = (async () => {
      try {
        setInProgress(true);
        await fetchJobs(controller);
        setInProgress(false);
      } catch (err) {
        // If it's aborted it's from the unmounting of the component
        setJobs(null);
        if (axios.isCancel(err)) {
          setInProgress(true);
        } else {
          setInProgress(false);
        }
      }
    });

    refreshJobs();
    const timer = setInterval(refreshJobs, 5_000);

    return () => {
      controller.abort();
      clearInterval(timer);
    }
  }, [fetchJobs]);


  const onProgressDetailsClicked = useCallback((jobId: number) => {
    setProgressDetailsJobId(jobId);
  }, [setProgressDetailsJobId]);

  const onCancelJobClicked = useCallback((jobId: number) => {
    setActiveCancelJobModalJobId(jobId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCancelJobModalJobId]);

  const jobOrderSelectOption = useMemo(() => {
    return sortByOptions.find(opt => opt.value === jobOrder);
  }, [ jobOrder ]);

  return (
    <div className="w-full border border-slate-200 p-2 bg-white">
      { progressDetailsJobId !== null && (
        <HostedScraperJobInfoModal jobList={jobs} jobId={progressDetailsJobId} onClose={() => setProgressDetailsJobId(null)} />
      )}
      { activeCancelJobModalJobId !== null && (
        <HostedScraperCancelJobModal jobId={activeCancelJobModalJobId} projectId={projectId} onClose={() => setActiveCancelJobModalJobId(null)} />
      )}
      <div className="m-4 flex flex-row items-center justify-between text-gray dark:text-neutral-600">

        <div className="flex flex-row items-center">
          Jobs
          { inProgress && <div className="ml-2"><Spinner className="w-3 h-3 animate-spin"/></div> }
        </div>
        <SortByListBox
          value={jobOrderSelectOption || { name: "Please select", value: undefined } }
          options={sortByOptions}
          onChange={ (newValue: SelectOption<JobOrder>) => setJobOrder(newValue.value) }
          className="w-[216px]"
        />
      </div>

      { jobs !== null && jobs.length === 0 && <EmptyJobList firstJobScheduled={supposedToRunAt}/> }
      { jobs !== null && jobs.length > 0 && (
        <div className="p-6 w-full">
          <div className="w-full">
            <table className="w-full divide-y divide-slate-200">
              <thead>
                {jobListHeader()}
              </thead>
              <tbody className="w-full divide-y divide-slate-200">
                {jobs.map(jobLine(projectId, onProgressDetailsClicked, onCancelJobClicked))}
              </tbody>
            </table>

            { jobs.length >= numberOfJobs &&
              (<div className="flex flex-col items-center">
                <Button text="Load more" className="button button-secondary" onClick={() => setNumberOfJobs(numberOfJobs + 10)} size="MD" />
              </div>)
            }
          </div>
        </div>
      )}
    </div>
  );
}
